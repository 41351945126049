$opacity-none: rgba(0,0,0,0);
$opcity-show: rgba(0,0,0,0.35);
$opcity-duration: 300ms;
$transofrm-duration: 100ms;

.general-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    left: 0px;
    right:0px;
    top: 0;
    bottom: 0px;
    background-color: $opacity-none;
    display: flex;
    justify-content: center;
    z-index: 1000;

    &-enter{
        background-color: $opacity-none;
    }
    &-enter-active{
        background-color: $opcity-show;
        transition: background-color $opcity-duration;
    }
    &-enter-done{
        background-color: $opcity-show;
        transition: background-color $opcity-duration;
    }
    &-exit{
        background-color: $opcity-show;
    }
    &-exit-active{
        background-color: $opacity-none;
        transition: background-color $opcity-duration linear;
    }
    &-exit-done{
        background-color: $opacity-none;
    }

    &__dialog{
        border: 0px solid coral;
        width: 573px;
   
        margin-top:4%;
        background-color: #FFF;
        transform: translateY(-1000px);
        border-radius: 15px;
        margin-bottom: 50px;
        position: relative; 
        overflow-y: hidden ;

        &--large{
            height: calc(100vh - 150px);
        }

        &--medium{
            height: calc(100vh - 350px);
        }

        &--small{
            height: calc(100vh - 550px);
        }

        @media screen and (max-width: 991px){
            border: 0px solid green;
            margin-top: 0px;
            margin-bottom: 0px;
            border-radius: 0px;
        }

    }
    &-enter-done &__dialog {
        transform: translateY(0px);
        transition: transform $transofrm-duration linear;
    }
    &-exit &__dialog{
        transform: translateY(-1000px);
        transition: transform $transofrm-duration linear;
    }
}


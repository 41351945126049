.table-cell-status {
    font-family: 'PoppinsRegular', Arial, sans-serif;
    font-size: 13px;
    padding: 5px 12px;
    border-radius: 12px;
    text-transform: capitalize;
    font-weight: 500;
   
    &--danger {
        // background-color: #FFF5F0;
        // color: #CE5A63;
        // background-color: #e3867d; /* Lighter Red */
        color: #f44336;
        border: 1px solid #f44336;
   

    }

    &--warning {
        // background-color: #fdfced;
        // color: #f77402;
        //         background-color: #ffdd4b; /* Lighter Yellow */
          color: #FF8C00;
          border: 1px solid #FF8C00;
    
    }

    &--success {
        // background-color: #EAF5EE;
        // color: #286840;

        //         background-color: #76c243; /* Lighter Green */
          color: #006400;
          border: 1px solid #006400;
    
    }
}